<template>
  <Card title="APP版本管理">
    <div>
      <div class="right" style="padding-bottom: 2vh">
        <a-space>
          <a-button @click="visible = true">下载二维码</a-button>
          <a-button type="primary" @click="add">新增</a-button>
        </a-space>
      </div>

      <a-modal
        title="点击图片下载二维码"
        :visible="visible"
        :footer="null"
        @cancel="visible = false"
        width="320px"
      >
        <a href="/zaoyang-download-app.png" target="_blank">
          <img src="/zaoyang-download-app.png" alt="" style="width: 100%" />
        </a>
      </a-modal>

      <a-table bordered :dataSource="list" :loading="loading" rowKey="id">
        <a-table-column title="名称" data-index="name"></a-table-column>
        <a-table-column title="类型" data-index="platformType">
        </a-table-column>
        <a-table-column title="路径" data-index="url"></a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a @click.prevent="edit(text)">编辑</a>
              <a class="danger" @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </Card>
</template>

<script>
import request from "@/api/request";

function fetchList(params) {
  return request({
    url: "/basic-service/softVersion/list",
    method: "get",
    params,
  });
}

function remove(data) {
  return request({
    url: "/basic-service/softVersion/del",
    method: "post",
    data,
  });
}

export default {
  data() {
    return {
      loading: false,
      list: [],

      visible: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: 1,
        pageSize: 99,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    add() {
      this.$router.push("/setting/app-version/add");
    },
    edit(text) {
      window.localStorage.setItem("version-detail", JSON.stringify(text));
      this.$router.push("/setting/app-version/edit");
    },
  },
};
</script>


<style lang="less" scoped>
.selector {
  position: fixed;
  top: 11vh;
  left: 17vw;
  right: 1vw;
  z-index: 99;
  background-color: var(--bg-color);

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .query {
    padding: 2vh 1vw 0;
  }

  .table {
    padding: 2vh 1vw;
  }
}
</style>
